import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  getEventDetails,
  getSponsorDetails,
  getSponsorWindowDetails,
  getSponsorMedia,
  postVectorInit,
  getShareTemplateLists,
} from "../apis/events";
import {
  HIGH_RES_DOMAIN,
  PRVACY_POLICY_ROUTE_NAME,
  VECTORIZE_CALL_EVERY_TIME,
  VECTORIZE_ON_NON_UPLOAD,
} from "../constants";

const Event = React.createContext();
Event.displayName = "Event";

export const useEvent = () => React.useContext(Event);

export const EventProvider = ({ children }) => {
  const [event, setEvent] = useState({ response: {}, error: false });

  useEffect(async () => {
    if (window.location.pathname !== PRVACY_POLICY_ROUTE_NAME) {
      try {
        let eventDetails = await getEventDetails();
        if (!eventDetails.error) {
          if (
            eventDetails.response.photographer.extraFeatures &&
            eventDetails.response.photographer.extraFeatures.sponsorBranding
          ) {
            const { data } = await getSponsorDetails(
              eventDetails.response.data.id
            );
            const sponsorDataPositionWise = {};
            data.forEach((el) => {
              if (
                sponsorDataPositionWise[el.position] &&
                sponsorDataPositionWise[el.position].value
              ) {
                sponsorDataPositionWise[el.position].image.push({
                  logo: el.logo_url,
                  link: el.link,
                });
              } else {
                sponsorDataPositionWise[el.position] = {
                  value: el.type,
                  image: [{ logo: el.logo_url, link: el.link }],
                };
              }
            });
            eventDetails.response.data.sponsorDetails = sponsorDataPositionWise;
          }
          if (
            eventDetails.response.photographer.extraFeatures &&
            eventDetails.response.photographer.extraFeatures
              .sponsorAdvertisement
          ) {
            try {
              const { data } = await getSponsorMedia(
                eventDetails.response.data.id
              );

              data.data.forEach((el) => {
                el.width = JSON.parse(el.meta_data).width ?? 16;
                el.height = JSON.parse(el.meta_data).height ?? 9;
                el.isVideo = el.is_video;
                if (el.is_video) {
                  el.type = "video";
                }

                el.src = "https://" + HIGH_RES_DOMAIN + "/" + el.url;
                el.isAd = true;
              });
              data.data.sort((a, b) => (a.priority > b.priority ? 1 : -1));
              eventDetails.response.data.partnerShowcase = data.data;
            } catch (error) {
              console.error(error);
              console.log("Error while fetching sponsor window");
            }
          }
          if (
            eventDetails.response.photographer.extraFeatures &&
            eventDetails.response.photographer.extraFeatures.sponsorWindow
          ) {
            try {
              const { data } = await getSponsorWindowDetails(
                eventDetails.response.data.id,
                eventDetails.response.photographer.userId
              );
              if (Object.keys(data).length) {
                eventDetails.response.data.sponsorWindow = data;
              }
            } catch (error) {
              console.error(error);
              console.log("Error while fetching sponsor window");
            }
          }

          if (
            eventDetails.response.photographer.extraFeatures &&
            eventDetails.response.photographer.extraFeatures.customSharing
          ) {
            try {
              const { data } = await getShareTemplateLists(
                eventDetails.response.data.id
              );
              if (data.data.length) {
                eventDetails.response.data.shareText = data.data;
              } else {
                eventDetails.response.data.shareText = [];
              }
            } catch (error) {
              console.error(error);
              console.log("Error while fetching custom share");
              eventDetails.response.data.shareText = [];
            }
          }
          setEvent(eventDetails);
          if (
            VECTORIZE_CALL_EVERY_TIME.includes(
              eventDetails.response.photographer.id
            )
          ) {
            postVectorInit(eventDetails.response.data.awsEventName);
          }
          // if (
          //   VECTORIZE_ON_NON_UPLOAD.includes(
          //     eventDetails.response.photographer.id
          //   ) &&
          //   !eventDetails.response.data.isUploading
          // ) {
          //   postVectorInit(eventDetails.response.data.awsEventName);
          // }
        } else {
          /**Reloading on checkout , or add address page and are going there from face search link */
          if (window.location.search.includes("view")) {
            let comingFromFaceAccess = false;
            window.location.search.split("&").forEach((el) => {
              if (el.includes("view")) {
                const accessLevel = el.split("=")[1];
                comingFromFaceAccess = accessLevel == 1 ? true : false;
              }
            });
            if (comingFromFaceAccess) {
              let eventName = window.location.pathname.split("/")[2];
              window.location.assign(
                `//${window.location.host}/facerec/${eventName}`
              );
            }
          }

          setEvent({ response: eventDetails, error: true });
        }
      } catch (e) {
        console.log(e);
        setEvent({ response: e, error: true });
      }
    }
  }, [localStorage.getItem("access_token")]);

  return <Event.Provider value={event}>{children}</Event.Provider>;
};
